import React, { useEffect, useState } from 'react';
import { Configuration, OpenAIApi } from 'openai';
import './App.css';



function Header({ darkMode, toggleDarkMode }) {
  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: darkMode ? '#333333' : '#ffffff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const logoStyle = {
    maxHeight: '50px',
    margin: 0,
  };

  const textStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0 0 0 10px',
    textDecoration: 'none',
    color: darkMode ? '#ffffff' : '#000000',
  };

  const iconStyle = {
    cursor: 'pointer', // Make the icon clickable
    marginLeft: 'auto',
  };

  

  return (
    <header style={{ ...headerStyle, ...iconStyle }} >
      <div>
        <a href="/">
          <img src="logo800.png" alt="Company Logo" style={logoStyle} />
        </a>
      </div>

      <div style={textStyle}>
        <a href="/" style={{ textDecoration: 'none', color: textStyle.color }}>
          CollegeAdmits
        </a>
      </div>

      <div style={iconStyle}onClick={toggleDarkMode}>
        {darkMode ? '☀️' : '🌙'}
      </div>
    </header>
  );
}


function Footer({ darkMode, toggleDarkMode }) {
  const footerStyle = {
    backgroundColor: darkMode ? '#333333' : '#004aad',
    padding: '30px',
    textAlign: 'center',
    fontSize: '16px',
    position: 'relative',
    bottom: '0',
    
  };

  const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
    marginLeft: '10px',
    marginRight: '10px',
  };

  const copyrightStyle = {
    marginTop: '20px',
    color: '#fff',
  }

  return (
    <div style={footerStyle}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <a href="/terms" style={linkStyle}>
          Terms of Use
        </a>

        <span style={{ display: 'inline-block', marginLeft: '10px', marginRight: '10px', color: '#fff'}}>
          |
        </span>

        <a href="/privacy" style={linkStyle}>
          Privacy Policy
        </a>
      </div>

      <div style={copyrightStyle}>
        © 2024 CollegeAdmits. All Rights Reserved.
      </div>
    </div>
  );
}



// ... (existing imports)

function Essayprompts() {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [loading, setLoading] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [option, setOption] = useState('');
  
  
    const configuration = new Configuration({
      apiKey: process.env.REACT_APP_OPENAPI_KEY,
    });
    const openai = new OpenAIApi(configuration);
  
    const onSelectOption = (selectedOption) => {
      setOption(selectedOption);
    };



    const renderSelectedPrompt = () => {
        if (option) {
          return (
            <p>{`Prompt ${option.slice(-1)}: ${getPromptText(option)}`}</p>
          );
        }
        return null;
      };
    
      const getPromptText = (selectedOption) => {
        const promptTexts = {
          prompt1: "Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story.",
          prompt2: "The lessons we take from obstacles we encounter can be fundamental to later success. Recount a time when you faced a challenge, setback, or failure. How did it affect you, and what did you learn from the experience?",
          prompt3: "Reflect on a time when you questioned or challenged a belief or idea. What prompted your thinking? What was the outcome?",
          prompt4: "Reflect on something that someone has done for you that has made you happy or thankful in a surprising way. How has this gratitude affected or motivated you?",
          prompt5: "Discuss an accomplishment, event, or realization that sparked a period of personal growth and a new understanding of yourself or others.",
          prompt6: "Describe a topic, idea, or concept you find so engaging that it makes you lose all track of time. Why does it captivate you? What or who do you turn to when you want to learn more?",
          prompt7: "Share an essay on any topic of your choice. It can be one you've already written, one that responds to a different prompt, or one of your own design.",
        };
    
        return promptTexts[selectedOption] || '';
      };
    
  
    // Define prompts based on the selected option
    let prompt = '';
    if (option === 'prompt1') {
      prompt = `I want you to generate a college application essay outline with this prompt: Some students have a background, identity, interest, 
      or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your 
      story, based on the user input. ${question}`;
    } else if (option === 'prompt2') {
      prompt = `I want you to generate a college application essay outline with this prompt: The lessons we take from obstacles we encounter can be 
      fundamental to later success. Recount a time when you faced a challenge, setback, or failure. How did it affect you, and what did you learn from 
      the experience?, based on the user input. ${question}`;
    } else if (option === 'prompt3') {
      prompt = `I want you to generate a college application essay outline with this prompt: Reflect on a time when you questioned or challenged a belief 
      or idea. What prompted your thinking? What was the outcome?, based on the user input. ${question}`;
    } else if (option === 'prompt4') {
      prompt = `I want you to generate a college application essay outline with this prompt: Reflect on something that someone has done for you that has made 
      you happy or thankful in a surprising way. How has this gratitude affected or motivated you?, based on the user input. ${question}`;
    } else if (option === 'prompt5') {
      prompt = `I want you to generate a college application essay outline with this prompt: Discuss an accomplishment, event, or realization that sparked a 
      period of personal growth and a new understanding of yourself or others., based on the user input. ${question}`;
    } else if (option === 'prompt6') {
      prompt = `I want you to generate a college application essay outline with this prompt: Describe a topic, idea, or concept you find so engaging that it makes 
      you lose all track of time. Why does it captivate you? What or who do you turn to when you want to learn more?, based on the user input. ${question}`;
    } else if (option === 'prompt7') {
      prompt = `I want you to generate a college application essay outline with this prompt: Share an essay on any topic of your choice. It can be one you've already 
      written, one that responds to a different prompt, or one of your own design., based on the user input. ${question}`;
    } else if (option === 'emailWriter') {
      prompt = `Write an email based on ${question}`;
    }
  
    const onChange = (e) => {
      setQuestion(e.target.value);
    };
  
    const onClick = async () => {
      if (!question) {
        alert("Please tell me about yourself and respond to the essay prompt.");
        return;
      }
  
      setLoading(true);
      const response = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [
          {"role": "system", "content": "I want you to give the answer to the question based on the user input."},
          {"role": "user", "content": prompt}, // Use the dynamically generated prompt
        ],
        temperature: 0.7,
        max_tokens: 550,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      });
  
      setLoading(false);
      setAnswer(response.data.choices[0].message.content);
    };
  
    const toggleDarkMode = () => {
      setIsDarkMode(!isDarkMode);
    };
  
    return (
      <div className={`page-container ${isDarkMode ? 'dark-mode' : ''}`}>
        <div className="content-wrap">
  
          <Header darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
  
          <h1>College Applications Essay Outline Generator</h1>
          <h2>Common App Essay Prompts</h2>
          <p>Tell me about yourself: include your gpa, test scores, volunteer opportunities, extracurricular activities, your interests, etc </p>

          <p></p>

          
          {renderSelectedPrompt()}
          
          {/* Dropdown menu for options */}
          <select
            style={{ width: '60%', padding: '10px', fontSize: '16px', backgroundColor: isDarkMode ? '#333' : '#fff',
            color: isDarkMode ? '#fff' : '#000', }}
            onChange={(e) => onSelectOption(e.target.value)}
            value={option}
          >
            <option value="" disabled>Select an option</option>
            <option value="prompt1">Prompt 1</option>
            <option value="prompt2">Prompt 2</option>
            <option value="prompt3">Prompt 3</option>
            <option value="prompt4">Prompt 4</option>
            <option value="prompt5">Prompt 5</option>
            <option value="prompt6">Prompt 6</option>
            <option value="prompt7">Prompt 7</option>
            {/* ... (add other options) */}
          </select>
  
          {/* Textarea for custom question */}
          <textarea
            onChange={onChange}
            value={question}
            rows={4}
            cols={50}
            placeholder={`3.84 gpa, 32 act score, over 100 hours volunteer tutoring, class president, captain school tennis team, interested in engineering`}
            maxLength={3600}
            style={{ marginTop: '10px', padding: '10px', fontSize: '16px' }}
            required
          />
  
          <button onClick={onClick}>Submit</button>
  
          <div className="container">
            {loading ? (
              <p className='answer'>CollegeAdmits is thinking ... </p>
            ) : (
              <p className='answer'>{answer}</p>
            )}
          </div>
  
          <div className="terms-spacer"></div>
  
        </div>
  
        <Footer darkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
  
      </div>
    );
  }
  
  export default Essayprompts;